import React from "react"
import { Row, Col } from "react-flexbox-grid"
import "./index.scss"
import Hero from "../../../components/Hero"
import Layout from "../../../components/Layout"
import ContentBlock from "../../../components/ContentBlock"
import HelmetWrapper from "../../../components/HelmetWrapper"
import CalloutBlock from "../../../components/CalloutBlock"

import grantCircle4 from "../../../assets/images/sm_circle_GrantProgram4.png"
import headerHero from "../../../assets/images/medical_grants.png"
import arrow from "../../../assets/images/chevron-circle-right-solid.svg"
import pdf from "../../../../static/static/Medical_Education_Grants_and_Sponsorship_Frequently_Asked_Questions.pdf"

const pageTitle = "Medical Education Grants | Harmony Biosciences"
const pageDescription =
  "Learn about our support of certain third-party independent, scientific and educational conferences. Review the criteria for medical education grants here."
const pageKeywords = ""
const smallScreenSize = () => {
  const _screenSize = window ? window.screen.width : 0
  return _screenSize <= 768 ? 66 : 155
}
class grantPrograms extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      offsetSize: 0,
    }
  }
  componentDidMount() {
    //alert(smallScreenSize())
    //console.log('hey')
    //console.log(screenSize())
    this.setState({
      offsetSize: smallScreenSize(),
    })
  }
  render() {
    return (
      <Layout jobCode="US-HAR-2200050/Jun 2022">
        <div id="medical-education-grants">
          <HelmetWrapper
            pageTitle={pageTitle}
            pageDescription={pageDescription}
            pageKeywords={pageKeywords}
          />
          <Hero bg={headerHero}>
            <h1>Medical Education Grants</h1>
          </Hero>

          <ContentBlock color="green" id="medical-education">
            <Row>
              <Col xs={12} md={2}>
                <Row center="xs">
                  <Col>
                    <img src={grantCircle4} className="" />
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={10}>
                <h2>Medical Education Grants &amp; Medical Sponsorships</h2>

                <p>
                  Harmony may support certain third-party independent,
                  scientific, and educational conferences or professional
                  meetings provided that the conference or meeting content:
                </p>
                <ul>
                  <li>
                    Contributes to the improvement of patient care or to the
                    enhanced delivery of healthcare; and/or
                  </li>
                  <li>Promotes or advances scientific and medical knowledge</li>
                </ul>

                <p>
                  <strong>
                    Medical education grants can be in the form of:
                  </strong>
                </p>
                <ul>
                  <li>CME-accredited programs</li>
                  <li>Non-CME-accredited programs</li>
                  <li>
                    Support of independent initiatives (e.g., treatment
                    guidelines, expert opinion papers, research-based career
                    development grants)
                  </li>
                </ul>

                <p>
                  <strong>Educational grants cannot be used to support:</strong>
                </p>
                <ul>
                  <li>
                    Requests outside Harmony’s therapeutic areas of interest
                  </li>
                  <li>Promotional/sponsorship activities or exhibits</li>
                  <li>
                    Payment for travel, lodging, or other personal expenses,
                    including time for HCPs attending a medical education
                    conference or professional meeting
                  </li>
                  <li>
                    Programs that have already occurred or for which content
                    development has been completed
                  </li>
                </ul>

                <p>
                  <strong>
                    Submit requests for medical education grants and medical
                    sponsorships to{" "}
                  </strong>
                  <a
                    href="mailto:grants@harmonybiosciences.com"
                    target="_blank"
                  >
                    grants@harmonybiosciences.com
                  </a>{" "}
                  with the following information:
                </p>
                <ul>
                  <li>Objective</li>
                  <li>Educational needs assessment</li>
                  <li>Budget</li>
                  <li>Venue(s)/delivery mechanism (e.g., webinar)</li>
                  <li>Accreditation(s) status</li>
                  <li>Target audience</li>
                  <li>Date and timeline for execution</li>
                  <li>Additional sources of funding, if any</li>
                </ul>
                <p>
                  Read{" "}
                  <a href={pdf} target="_blank">
                    Frequently Asked Questions
                  </a>{" "}
                  regarding Medical Education Grants and Sponsorships.{" "}
                </p>
                <p className="footnote-small">
                  No grants, sponsorships, or charitable contributions are
                  offered or provided in exchange for endorsing or prescribing
                  Harmony products or for a commitment to continue prescribing
                  Harmony products. In other words, any quid pro quo is strictly
                  prohibited.
                </p>
                <p className="footnote-small">
                  Any funding provided by Harmony will be in compliance with all
                  applicable laws, rules, and regulations.
                </p>
              </Col>
            </Row>
          </ContentBlock>

          <Row className={"callout-row"}>
            <Col xs={12} md={6}>
              <CalloutBlock
                className="text-only bg-blue"
                link="/funding-programs/patients-at-the-heart"
              >
                <h2>PATIENTS AT THE HEART</h2>
                <img className="callout-arrow" src={arrow} />
              </CalloutBlock>
            </Col>
            <Col xs={12} md={6}>
              <CalloutBlock
                className="text-only bg-rose"
                link="/funding-programs/progress-at-the-heart"
              >
                <h2>PROGRESS AT THE HEART</h2>
                <img className="callout-arrow" src={arrow} />
              </CalloutBlock>
            </Col>
            <Col xs={12} md={6}>
              <CalloutBlock
                className="text-only bg-orange gap"
                link="/funding-programs/external-funding"
              >
                <h2>EXTERNAL FUNDING</h2>
                <img className="callout-arrow" src={arrow} />
              </CalloutBlock>
            </Col>
            <Col xs={12} md={6}>
              <CalloutBlock
                className="text-only bg-gray gap"
                link="/funding-programs/investigator-sponsored-studies"
              >
                <h2>INVESTIGATOR SPONSORED STUDIES</h2>
                <img className="callout-arrow" src={arrow} />
              </CalloutBlock>
            </Col>
          </Row>
        </div>
      </Layout>
    )
  }
}

export default grantPrograms
